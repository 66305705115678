import React from 'react'
import Image from '../../images/recycle.webp'

export default function Section2() {
  return (
    <div>
        <section class="sm:py-20 py-10 px-2 sm:px-24 bg-blue-50 overflow-hidden" style={{fontFamily:'Red Hat Display'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap -m-8">
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="xl:pt-12">
          <h2 class="mb-7 text-3xl md:text-4xl text-blue-800 font-bold tracking-px-n leading-tight">Recycling e-waste for a sustainable and greener future.</h2>
          <p class="text-gray-900 text-opacity-80 font-medium">Through State-of-the-art facilities and advanced recycling techniques, we ensure your E-Waste is processed in an efficient, secure and eco-friendly manner.</p>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 sm:p-16 p-8">
        <div class="flex flex-wrap justify-center">
          <div class="relative inline-block rounded-3xl overflow-hidden shadow-3xl transform hover:-translate-y-4 transition ease-in-out duration-1000">
            <img src={Image} alt=""/>
            <div class="absolute bottom-0 left-0 bg-blue-800 bg-opacity-30 backdrop-blur-xl py-8 px-9" style={{backdropFilter: 'blur(5px)'}}>
              <p class="mb-3 font-sans inline-block px-3.5 py-1.5 text-xs text-white font-semibold bg-red-900 uppercase tracking-px rounded-full">Live</p>
              <h3 class="mb-3 text-xl font-bold font-heading text-white">We Plant a Tree for every MetricTon Recycling</h3>
              <p class="text-sm text-gray-100 font-semibold">50+ trees planted so far.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3 p-8">
        <div class="flex flex-wrap h-full xl:pb-24">
          <div class="flex flex-wrap self-end -m-10">
            <div class="w-full p-10">
              <div class="md:max-w-sm">
                <h3 class="mb-5 text-2xl text-blue-800 font-bold leading-snug">Together, for better world.</h3>
                <p class="text-gray-900 text-opacity-70 font-medium leading-relaxed">At TerraNova, we believe in the power of collective action for a better world. Join us in our mission to create a sustainable future through responsible recycling practices, innovative technologies, and community engagement.</p>
              </div>
            </div>
            <div class="w-full px-10">
              <div class="h-px bg-yellow-400"></div>
            </div>
            <div class="w-full p-10">
              <div class="md:max-w-sm">
                <h3 class="mb-5 text-2xl text-blue-800 font-bold leading-snug">Join Hands with us</h3>
                <p class="text-gray-900 text-opacity-70 font-medium leading-relaxed">Join us as we revolutionize the recycling industry, promoting a circular economy through our state-of-the-art facilities and expert team. Together, let's create a greener world for generations to come, one meeting at a time.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
