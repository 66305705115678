import React from "react";
import Image from "../../images/Picture15.jpg";
import Image1 from "../../images/Picture16.jpg";
import Image2 from "../../images/Picture17.jpg";

export default function Section2() {
  return (
    <div>
      <section
        class="relative py-20 md:py-10 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-2xl lg:max-w-3xl mx-auto xl:max-w-7xl">
            <h2 class="sm:mb-14 mb-10 text-4xl md:text-4xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none text-blue-800">
              Data Sanitization Area
            </h2>
            <div class="flex flex-wrap -mx-4 -mb-12">
              <div class="w-full md:w-1/2 xl:w-1/3 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image1}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/3 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block mb-4 w-full h-72 object-cover rounded-xl"
                    src={Image2}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
