import React from "react";
import backgroundImageOne from "../../../images/ewaste.webp";
import { Link } from "react-router-dom";

export default function Sectionone() {
  return (
    <div>
      <section
        class="sm:py-28 py-16 bg-white bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
        style={{ backgroundImage: `url(${backgroundImageOne})` }}
      >
        <div
          class="container sm:px-4 px-2 mx-auto"
          style={{ fontFamily: "Red Hat Display" }}
        >
          <div
            class="sm:px-12 px-4 pt-6 pb-10 sm:pt-12 sm:pb-9 sm:ml-14  md:max-w-lg bg-opacity-80 rounded-xl bg-blue-800 bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600"
            style={{ backdropFilter: "10px" }}
          >
            <p class="sm:mb-7 mb-4 font-sans max-w-max px-3 py-1.5 text-sm text-white font-semibold uppercase border border-gray-700 rounded-md">
              Services
            </p>
            <h2 class="mb-4 text-2xl md:text-4xl text-white font-bold font-heading tracking-px-n leading-tight">
              Transforming E-Waste into Environmental Solutions
            </h2>
            <p class="mb-11 text-lg font-medium leading-normal text-gray-100">
              E-waste is a growing problem – but there are ways to properly
              manage it and recycle what you can't use.We're here to help you
              sort through your options and find the best way to recycle or
              dispose of your e-waste.
            </p>
            <Link
              to="/"
              class="inline-flex flex-wrap items-center text-white hover:text-gray-200"
            >
              <span class="mr-2 font-semibold leading-normal">
                Read Full Article
              </span>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
