import React from "react";
import Pic from "../../images/PCB1.png";
import Pic1 from "../../images/PCB2.png";
import Pic2 from "../../images/PCB3.png";
import Pic3 from "../../images/PCB4.png";
import Pic4 from "../../images/PCB5.png";
import Pic5 from "../../images/PCB6.png";
import Pic6 from "../../images/PCB7.png";
import Pic7 from "../../images/PCB8.png";

export default function Section7() {
  return (
    <div>
      <section
        class="relative py-20 md:py-10 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="relative container px-4 mx-auto">
          <div class="max-w-2xl lg:max-w-3xl mx-auto xl:max-w-7xl">
            <div class="flex flex-wrap mb-10 -mx-4 items-center">
              <div class="w-full xl:w-1/2 px-4">
                <div>
                  <h2 class="text-2xl md:text-4xl font-bold font-heading tracking-px-n leading-tight mb-5 text-blue-800 text-center">
                    Advanced PCB Recycling Line & Cable Recycling Line
                  </h2>
                </div>
              </div>
              <div class="w-full xl:w-1/2 px-4">
                <div class="max-w-lg xl:ml-auto">
                  <p class="text-xl font-semibold text-gray-800 mb-6">
                    This set up includes 500 Kg/hr PCB Recycling Line, and Cable
                    Recycling line which does complete end to end Metal Non
                    Metal Separation.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-4 -mb-12">
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic1}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic2}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic3}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic4}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic5}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic6}
                    alt=""
                  />
                </div>
              </div>
              <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                <div class="max-w-xs md:max-w-none mx-auto">
                  <img
                    class="block w-full h-72 object-cover rounded-md"
                    src={Pic7}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
