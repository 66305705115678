import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 py-12 px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="150"
                          fill="#6495ed"
                        />
                        <rect
                          x="170"
                          y="220"
                          width="160"
                          height="110"
                          fill="#ffffff"
                        />
                        <rect
                          x="200"
                          y="250"
                          width="100"
                          height="50"
                          fill="#6495ed"
                        />
                        <circle cx="250" cy="250" r="40" fill="#ffffff" />
                        <line
                          x1="250"
                          y1="210"
                          x2="250"
                          y2="290"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="210"
                          y1="250"
                          x2="290"
                          y2="250"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <circle cx="250" cy="250" r="10" fill="#6495ed" />
                        <circle cx="240" cy="240" r="5" fill="#6495ed" />
                        <circle cx="260" cy="240" r="5" fill="#6495ed" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-lg font-semibold">
                      {" "}
                      We offer site upgrades and expansion services to enhance
                      the capacity and coverage of telecom sites
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#008000" />
                        <path
                          d="M250 100 L400 250 L250 400 L100 250 Z"
                          fill="#ffffff"
                        />
                        <circle cx="250" cy="250" r="120" fill="#008000" />
                        <circle cx="250" cy="250" r="70" fill="#ffffff" />
                        <rect
                          x="220"
                          y="180"
                          width="60"
                          height="140"
                          fill="#ffffff"
                        />
                        <rect
                          x="180"
                          y="220"
                          width="140"
                          height="60"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-lg font-semibold">
                      Ensuring the safe removal of equipment, dismantling of
                      structures, and proper disposal or recycling of materials.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="150"
                          fill="#ffcc00"
                        />
                        <rect
                          x="170"
                          y="220"
                          width="160"
                          height="110"
                          fill="#ffffff"
                        />
                        <rect
                          x="200"
                          y="250"
                          width="100"
                          height="50"
                          fill="#ffcc00"
                        />
                        <rect
                          x="220"
                          y="220"
                          width="60"
                          height="10"
                          fill="#ffcc00"
                        />
                        <circle cx="250" cy="260" r="5" fill="#ffcc00" />
                        <rect
                          x="225"
                          y="270"
                          width="50"
                          height="30"
                          fill="#ffcc00"
                        />
                        <rect
                          x="200"
                          y="300"
                          width="100"
                          height="20"
                          fill="#ffcc00"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-lg font-semibold">
                      We provide site security and surveillance solutions,
                      including the installation and maintenance of CCTV cameras
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
