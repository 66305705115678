import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 sm:py-20 py-12   sm:px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container sm:px-8 px-3 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center sm:w-20 sm:h-20 w-16 h-16  bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#008000" />
                        <rect
                          x="200"
                          y="120"
                          width="100"
                          height="260"
                          fill="#ffffff"
                        />
                        <path
                          d="M250 120 L380 250 L250 380 L120 250 Z"
                          fill="#006400"
                        />
                        <circle cx="250" cy="250" r="50" fill="#ffffff" />
                        <path
                          d="M250 200 Q250 150 300 150 A50 50 0 1 1 200 150 Q250 150 250 200"
                          fill="#008000"
                        />
                        <circle cx="250" cy="250" r="20" fill="#ffffff" />
                        <circle cx="250" cy="250" r="10" fill="#008000" />
                        <circle cx="230" cy="270" r="10" fill="#008000" />
                        <circle cx="270" cy="270" r="10" fill="#008000" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="sm:text-xl text-lg font-semibold">
                      Environmental protection through responsible&nbsp; e-waste
                      recycling.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center sm:w-20 sm:h-20 w-16 h-16  bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#ffd700" />
                        <path
                          d="M250 100 L400 250 L250 400 L100 250 Z"
                          fill="#ffd700"
                        />
                        <circle cx="250" cy="250" r="160" fill="#ffffff" />
                        <rect
                          x="190"
                          y="120"
                          width="120"
                          height="260"
                          fill="#ffd700"
                        />
                        <rect
                          x="220"
                          y="180"
                          width="60"
                          height="140"
                          fill="#ffffff"
                        />
                        <circle cx="250" cy="250" r="70" fill="#ffd700" />
                        <circle cx="250" cy="250" r="50" fill="#ffffff" />
                        <rect
                          x="235"
                          y="235"
                          width="30"
                          height="30"
                          fill="#ffd700"
                        />
                        <line
                          x1="250"
                          y1="235"
                          x2="250"
                          y2="265"
                          stroke="#ffffff"
                          stroke-width="3"
                        />
                        <line
                          x1="235"
                          y1="250"
                          x2="265"
                          y2="250"
                          stroke="#ffffff"
                          stroke-width="3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="sm:text-xl text-lg font-semibold">
                      Compliance with regulations and promotion of a circular
                      economy.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center sm:w-20 sm:h-20 w-16 h-16  bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#6abf7b" />
                        <path
                          d="M250 100 L400 250 L250 400 L100 250 Z"
                          fill="#8fd596"
                        />
                        <circle cx="250" cy="250" r="160" fill="#ffffff" />
                        <path
                          d="M250 120 Q200 170 250 220 Q300 170 250 120"
                          fill="#6abf7b"
                        />
                        <path d="M220 240 L250 270 L280 240 Z" fill="#6abf7b" />
                        <path d="M230 270 Q250 300 270 270" fill="#6abf7b" />
                        <circle cx="250" cy="250" r="20" fill="#8fd596" />
                        <circle cx="220" cy="220" r="15" fill="#8fd596" />
                        <circle cx="280" cy="220" r="15" fill="#8fd596" />
                        <circle cx="250" cy="300" r="15" fill="#8fd596" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="sm:text-xl text-lg  font-semibold">
                      Contribution to a sustainable future and a greener planet.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
