import React from 'react'
import { BrowserRouter, Route, Routes,NavLink,Link } from 'react-router-dom'
import Logo from '../src/images/logo1.png'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ScrollToTop from "react-scroll-to-top"
import Appwrap from './components/global/appwrap'
import Homepage from './components/homepage/index'
import EWastepage from './components/services/ewaste'
import ITAD from './components/services/itad'
import EPR from './components/services/epr'
import Logistics from './components/services/logistics'
import PreciousMetalRecovery from './components/services/preciousmetalrecovery'
import DataCenterServices from './components/services/datacenterservices'
import Telecom from './components/services/telecomsiteoperations'
import Aboutus from './components/pages/about'
import Contactus from './components/pages/contact'
import Buyfromus from './components/services/buyfromus/index'
import Facility from './components/facilities/index'
import Epr from './components/pages/epr'

export default function App() {
  return (
    <div>
       <FloatingWhatsApp
        phoneNumber="+18003093201"
        accountName="Terranova"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={Logo}
      />
      <div>
   

   <ScrollToTop  smooth className="mb-16 pl-2" color ="blue" />
 </div>

<BrowserRouter>
<Appwrap>
<Routes>
  <Route exact path="/" element={<Homepage/>}/>
  <Route exact path="/ewaste" element={<EWastepage/>}/>
  <Route exact path="/itad" element={<ITAD/>}/>
  <Route exact path="/epr" element={<EPR/>}/>
  <Route exact path="/logistics" element={<Logistics/>}/>
  <Route exact path="/preciousmetalrecovery" element={<PreciousMetalRecovery/>}/>
  <Route exact path="/telecomsiteoperations" element={<Telecom/>}/>
  <Route exact path="/datacenterservices" element={<DataCenterServices/>}/>
  <Route exact path="/about" element={<Aboutus/>}/>
  <Route exact path="/contact" element={<Contactus/>}/>
  <Route exact path="/buyfromus" element={<Buyfromus/>}/>
  <Route exact path="/facilities" element={<Facility/>}/>
  <Route exact path="/eprr" element={<Epr/>}/>
</Routes>
</Appwrap>
</BrowserRouter>

    </div>
  )
}
