import React from "react";
import Img from "../../images/Picture21.jpg";
import Img1 from "../../images/Picture22.jpg";
import Img2 from "../../images/Picture23.jpg";
import Img3 from "../../images/Picture24.jpg";
import Img4 from "../../images/Picture25.jpg";

export default function Section1() {
  return (
    <div>
      <section>
        <div
          class="pt-8 bg-gray-50 radius-for-skewed"
          style={{ fontFamily: "Red Hat Display" }}
        >
          <div class="container mx-auto px-4">
            <div class="mb-6 flex flex-wrap justify-center">
              <div class="mb-16 w-full ml-20">
                <h2 class="text-4xl md:text-4xl xl:text-10xl font-bold font-heading tracking-px-n leading-none text-blue-800">
                  Degaussing Process
                </h2>
              </div>
              <div class="flex flex-wrap -mx-3">
                <div class="mb-6 w-full lg:w-1/2 px-3">
                  <div class="rounded overflow-hidden">
                    <img class="lg:h-96 rounded-xl ml-10" src={Img4} alt="" />
                    <div class="mt-auto p-6">
                      <h2 class="my-2 text-lg font-medium">
                        Operators simply slide the catch and the lid open
                        automatically. Place the media into the compartment and
                        close the lid. The Erase cycle is started by pressing
                        the erase button on the control panel, which activates
                        the charging of the powerful degaussing magnetic field.
                        When the display indicates the degauss cycle is complete
                        the lid can be open and the media removed. The entire
                        process takes just 45 seconds.
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap w-full lg:w-1/2">
                  <div class="mb-6 w-full lg:w-1/2 px-3">
                    <div class="rounded overflow-hidden">
                      <img class="lg:h-60 rounded-xl" src={Img} alt="" />
                    </div>
                  </div>
                  <div class="mb-6 w-full lg:w-1/2 px-3">
                    <div class="rounded overflow-hidden">
                      <img class="lg:h-60 rounded-xl" src={Img1} alt="" />
                    </div>
                  </div>
                  <div class="mb-6 w-full lg:w-1/2 px-3">
                    <div class="rounded overflow-hidden">
                      <img class="lg:h-60 rounded-xl" src={Img2} alt="" />
                    </div>
                  </div>
                  <div class="mb-6 w-full lg:w-1/2 px-3">
                    <div class="rounded overflow-hidden">
                      <img class="lg:h-60 rounded-xl" src={Img3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
