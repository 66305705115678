import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 py-12 px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="150"
                          width="200"
                          height="200"
                          fill="#6495ed"
                        />
                        <rect
                          x="175"
                          y="175"
                          width="150"
                          height="150"
                          fill="#ffffff"
                        />
                        <circle cx="250" cy="250" r="70" fill="#6495ed" />
                        <line
                          x1="250"
                          y1="220"
                          x2="250"
                          y2="280"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="220"
                          y1="250"
                          x2="280"
                          y2="250"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <circle cx="250" cy="250" r="40" fill="#ffffff" />
                        <line
                          x1="250"
                          y1="230"
                          x2="250"
                          y2="270"
                          stroke="#6495ed"
                          stroke-width="2"
                        />
                        <line
                          x1="230"
                          y1="250"
                          x2="270"
                          y2="250"
                          stroke="#6495ed"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Safeguard the environment and promote sustainability with
                      our asset recycling service.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#003366" />
                        <circle cx="250" cy="250" r="160" fill="#ffffff" />
                        <path
                          d="M250 90 L380 250 L250 410 L120 250 Z"
                          fill="#003366"
                        />
                        <rect
                          x="200"
                          y="200"
                          width="100"
                          height="100"
                          fill="#003366"
                        />
                        <circle cx="250" cy="250" r="50" fill="#ffffff" />
                        <line
                          x1="250"
                          y1="200"
                          x2="250"
                          y2="300"
                          stroke="#003366"
                          stroke-width="4"
                        />
                        <line
                          x1="200"
                          y1="250"
                          x2="300"
                          y2="250"
                          stroke="#003366"
                          stroke-width="4"
                        />
                        <circle cx="250" cy="250" r="30" fill="#003366" />
                        <circle cx="250" cy="250" r="15" fill="#ffffff" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Ensuring data protection and complaince with our secure
                      data destruction service
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="100"
                          y="150"
                          width="300"
                          height="200"
                          fill="#6495ed"
                        />
                        <rect
                          x="120"
                          y="170"
                          width="260"
                          height="160"
                          fill="#ffffff"
                        />
                        <rect
                          x="180"
                          y="220"
                          width="60"
                          height="60"
                          fill="#6495ed"
                        />
                        <rect
                          x="260"
                          y="220"
                          width="60"
                          height="60"
                          fill="#6495ed"
                        />
                        <line
                          x1="250"
                          y1="190"
                          x2="250"
                          y2="280"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="220"
                          y1="250"
                          x2="280"
                          y2="250"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <circle cx="180" cy="180" r="10" fill="#6495ed" />
                        <circle cx="220" cy="180" r="10" fill="#6495ed" />
                        <circle cx="260" cy="180" r="10" fill="#6495ed" />
                        <circle cx="300" cy="180" r="10" fill="#6495ed" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Our Logistics and asset tracking service streamlines
                      logistics of IT asset Disposition
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
