import React from "react";
import { Link } from "react-router-dom";

import Machine from "../../images/cctv6.jpg";
import Machine1 from "../../images/cctv2.jpg";
import Machine2 from "../../images/cctv3.jpg";
import Machine3 from "../../images/cctv4.jpg";
import Machine4 from "../../images/cctv5.jpg";

export default function Section5() {
  return (
    <div>
      <section
        class="py-8 sm:px-2 px-4 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container mx-auto">
          <h2 class="text-2xl md:text-4xl font-bold font-heading tracking-px-n leading-tight text-blue-800 text-center">
            Security System
          </h2>
          <p class="mt-5 text-lg text-gray-800 font-semibold text-center">
            {" "}
            Security System Includes Secured Warehouse with CCTV Coverage, CCTV
            Monitoring & Recording, Dedicated Server, Access control to every
            section
          </p>
          <div class="mb-20 flex flex-wrap items-end -m-8"></div>
          <div class="flex flex-wrap -m-3">
            <div class="w-full md:w-1/5 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-md overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={Machine}
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/5 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-md overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={Machine1}
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/5 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-md overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={Machine2}
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/5 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-md overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={Machine3}
                    alt=""
                  />
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/5 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-md overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={Machine4}
                    alt=""
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
