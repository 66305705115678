import React from "react";
import Logo1 from "../../images/certification1.png";
import Logo2 from "../../images/certification2.png";
import Logo3 from "../../images/certification3.png";
import Logo4 from "../../images/certification4.png";

export default function Section7() {
  return (
    <div>
      <section class="sm:pt-10 sm:pb-10 pt-10  bg-white overflow-hidden">
        <div class="container px-6 mx-auto">
          <h2 class="sm:mb-14 mb-4  text-4xl md:text-2xl xl:text-4xl text-blue-800 text-center font-bold font-heading tracking-px-n leading-none md:max-w-2xl mx-auto">
            Certifications & Accreditations
          </h2>
          <div class="flex flex-wrap justify-center">
            <div class=" w-56 h-56 pr-10 px-2 pt-8 ">
              <img src={Logo1} alt="" />
            </div>
            <div class=" w-52 h-52 px-12 pl-3">
              <img src={Logo2} alt="" />
            </div>
            <div class="w-52 h-52 px-12 pl-3">
              <img src={Logo3} alt="" />
            </div>
            <div class="w-56 h-56 px-2 pl-4 pt-8">
              <img src={Logo4} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
