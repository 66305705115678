import React from "react";
import SectionOne from "./sectionone";
import SectionTwo from "./sectiontwo";
import SectionThree from "./sectionthree";

export default function Index() {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
    </div>
  );
}
