import React from "react";
import Image3 from "../../images/background.png";

export default function Section6() {
  return (
    <div>
      <section class="bg-white  overflow-hidden">
        <div class="relative bg-white overflow-hidden">
          <img
            class="absolute left-1/2 bottom-0 transform -translate-x-1/2"
            src="flaro-assets/images/applications/gradient2.svg"
            alt=""
          />
          <img
            class="relative z-10 mx-auto transform hover:scale-105 transition ease-in-out duration-1000"
            src={Image3}
            alt=""
          />
        </div>
      </section>
    </div>
  );
}
