import React from "react";
import SectionOne from "./sectionone";
import SectionTwo from "./sectiontwo";

export default function Index() {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
    </div>
  );
}
