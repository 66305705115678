import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 py-12 px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="100"
                          fill="#6495ed"
                        />
                        <path d="M150 200 L250 100 L350 200 Z" fill="#003366" />
                        <rect
                          x="190"
                          y="220"
                          width="120"
                          height="60"
                          fill="#ffffff"
                        />
                        <text
                          x="250"
                          y="255"
                          text-anchor="middle"
                          font-size="40"
                          font-family="Arial"
                          fill="#003366"
                        >
                          +$
                        </text>
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Opportunity for additional revenue generation{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="100"
                          fill="#808080"
                        />
                        <rect
                          x="180"
                          y="220"
                          width="140"
                          height="60"
                          fill="#ffffff"
                        />
                        <rect
                          x="210"
                          y="230"
                          width="80"
                          height="40"
                          fill="#808080"
                        />
                        <path d="M210 230 L290 230 L250 170 Z" fill="#000000" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      {" "}
                      Environmental impact of mining and extraction processes.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#a9a9a9" />
                        <circle cx="250" cy="250" r="150" fill="#d3d3d3" />
                        <circle cx="250" cy="250" r="100" fill="#a9a9a9" />
                        <circle cx="250" cy="250" r="50" fill="#d3d3d3" />
                        <line
                          x1="250"
                          y1="0"
                          x2="250"
                          y2="500"
                          stroke="#000000"
                          stroke-width="3"
                        />
                        <line
                          x1="0"
                          y1="250"
                          x2="500"
                          y2="250"
                          stroke="#000000"
                          stroke-width="3"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      {" "}
                      Extraction of new metals from ore.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
