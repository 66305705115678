import React from "react";
import { Link } from "react-router-dom";
import { TiSocialFacebook } from "react-icons/ti";

export default function Footer() {
  return (
    <div>
      <section
        class="relative sm:pt-10 sm:pb-10 pb-10 pt-10 sm:px-24 px-2 bg-gray-800 overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <img
          class="absolute left-1/2 top-0 transform -translate-x-1/2"
          src="flaro-assets/images/footers/gradient3.svg"
          alt=""
        />
        <div class="relative z-10 container px-4 mx-auto">
          <div class="flex flex-wrap -m-8">
            <div class="w-full md:w-1/2 p-8">
              <div class="lg:max-w-sm">
                <h2 class="mb-16 text-4xl md:text-5xl text-white font-bold tracking-px-n leading-tight">
                  Let&rsquo;s talk about your project.
                </h2>
                <div class="md:inline-block">
                  <button
                    class="py-4 px-6 w-full text-gray-900 font-semibold rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-yellow-500 hover:bg-gray-50 transition ease-in-out duration-200"
                    type="button"
                  >
                    Get A Free Audit
                  </button>
                  <span class="[&>svg]:h-5 [&>svg]:w-5"></span>
                </div>
              </div>
              <div class="flex items-center gap-6 lg:pt-10 lg:ml-2">
                <a
                  class="text-gray-500 hover:text-blue-600"
                  href=" https://www.linkedin.com/company/103818148/admin/feed/posts/?feedType=following"
                  target=" https://www.linkedin.com/company/103818148/admin/feed/posts/?feedType=following"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="h-8"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </a>

                <a
                  class="text-gray-500 hover:text-blue-600"
                
                  href="https://www.facebook.com/profile.php?id=61559311554429"
                  target="https://www.facebook.com/profile.php?id=61559311554429"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    class="h-8"
                  >
                    <path
                      fill="currentColor"
                      d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    ></path>
                  </svg>
                </a>
                <a
                  class="text-gray-500 hover:text-orange-600"
                  aria-label="Visit TrendyMinds Instagram"
                  href="https://www.instagram.com/terranova_eco_management?igsh=MTk0Yjc3d29odG10Zg%3D%3D&utm_source=qr"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="h-8"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="flex flex-wrap -m-8 mb-10">
                <div class="w-full sm:w-1/3 p-8 ">
                  <div>
                    <Link to="/" class="mb-3.5">
                      <a class="text-white hover:text-gray-200 font-semibold text-3xl leading-relaxed">
                        Home
                      </a>
                    </Link>
                  </div>
                  <div class="mt-4">
                    <Link to="/about" class="mb-3.5">
                      <a class=" text-white hover:text-gray-200 font-semibold leading-relaxed">
                        About
                      </a>
                    </Link>
                  </div>
                  <Link to="/contact">
                    <a class="text-white hover:text-gray-200 font-medium leading-relaxed">
                      Contact
                    </a>
                  </Link>
                </div>
                <div class="block sm:hidden w-full sm:w-1/3 p-8">
                  <ul>
                    <li class="mb-4">
                      <a class="text-white hover:text-gray-200 font-semibold text-3xl leading-relaxed">
                        Services
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="hidden sm:block w-full sm:w-1/3 p-8">
                  <ul>
                    <li class="mb-3.5">
                      <a class="text-white hover:text-gray-200 font-semibold text-3xl leading-relaxed">
                        Services
                      </a>
                    </li>
                    <div>
                      <Link to="/ewaste" class="mb-2">
                        <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                          E-Waste Recycling
                        </a>
                      </Link>
                    </div>
                    <Link
                      to="/itad"
                      class="mb-2 text-white hover:text-gray-200 font-normal leading-relaxed"
                    >
                      IT Asset Management{" "}
                    </Link>
                    <div>
                      <Link to="/preciousmetalrecovery" class="mb-2">
                        <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                          Precious metal Recovery
                        </a>
                      </Link>
                    </div>
                    <Link to="/telecomsiteoperations" class="mb-2">
                      <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                        Telecom Site Operations
                      </a>
                    </Link>
                  </ul>
                </div>
                <div class="hidden sm:block w-full sm:w-1/3 p-8 mt-16">
                  <ul>
                    <Link to="/logistics" class="mb-2">
                      <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                        Reverse Logistics
                      </a>
                    </Link>
                    <div>
                      <Link to="/epr" class="mb-2">
                        <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                          EPR Implementation
                        </a>
                      </Link>
                    </div>
                    <div>
                      <Link to="/datacenterservices" class="mb-2">
                        <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                          Data Center Services
                        </a>
                      </Link>
                    </div>
                    <div>
                      <Link to="/buyfromus" class="mb-2">
                        <a class="text-white hover:text-gray-200 font-semibold leading-relaxed">
                          Buy From Us
                        </a>
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
              <p class="text-sm text-white text-opacity-50 font-medium leading-relaxed">
                Copyright &copy; 2023 TerraNova. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
