import React from "react";

export default function Epr() {
  return (
    <div>
      <section
        class="py-8 bg-blueGray-50"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <div class="p-7 pb-12 pt-12 bg-white rounded-5xl">
            <h2 class="pl-10 xl:pl-24 text-3xl font-heading font-bold mb-10">
              List of Godowns/ Warehouses as Collection Centers:
            </h2>
            <div class="overflow-x-auto">
              <div class="inline-block min-w-full overflow-hidden">
                <table class="mb-10 table-auto w-full">
                  <thead>
                    <tr>
                      <th class="pl-10 xl:pl-24 h-20 bg-white text-left">
                        <span class="block text-lg text-body text-opacity-40 font-heading font-bold uppercase min-w-max">
                          Sl N0.
                        </span>
                      </th>
                      <th class="p-5 h-20 bg-white">
                        <span class="block text-lg text-body text-opacity-40 font-heading font-bold uppercase min-w-max">
                          State
                        </span>
                      </th>
                      <th class="p-5 h-20 bg-white">
                        <span class="block text-lg text-body text-opacity-40 font-heading font-bold uppercase min-w-max">
                          City
                        </span>
                      </th>
                      <th class="p-5 h-20 bg-white">
                        <span class="block text-lg text-body text-opacity-40 font-heading font-bold uppercase min-w-max">
                          Address
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            1{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            New Delhi
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            New Delhi
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Plot No. 619, Block A, Near Tata Telco Service
                            Station, Rangpuri, Mahipalpur, New Delhi - 110037
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-white rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            2
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Haryana
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Gurgaon
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            {" "}
                            J-171, New Palam Vihar Phase - 1, Gurgaon, Gurugram,
                            Haryana - 122017
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            3
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Uttar Pradesh
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            {" "}
                            Noida
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Bh-122, Sector-70, Noida, Uttar Pradesh - 201301
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-white rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            4
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Maharashtra
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Mumbai
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-white rounded-tr-5xl rounded-br-5xl">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Plot no 92, Ground Floor, Gala/Shop No 1, Behind
                            APMC Police Station, Next to Shell Inn Lodge, APMC
                            Market - I, Sector 19c, Vashi, Navi Mumbai - 400703
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            5
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Maharashtra
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Pune
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Ploat No-24,Sec No-4,Shikshak Colony, Near Spine
                            City, Moshi Pradhikaran, Pune-412105
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            6
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Telangana
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Hyderabad
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Plot No. 111, Near Om Sai Nilayam Apartment,
                            Quthbullapum, Near Suchitra, Bhagya Lakhmi Colony,
                            Road No. 4, Jeedimetla, Hyderabad, Telangana-500055
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            7
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Karnataka
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Bengaluru
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            BhasNo.43 1st floor 2nd Main D.D.U.T.T.L.
                            Yeshwantpur Bangalore-560022dfg
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            8
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Karnataka
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Mangaluru
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Opp.Hindustan Lzever Ltd, Sulthan Bhathery Road
                            Boloor Mangalore-575003
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            9
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Tamilnadu
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Chennai
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            3/27 Sakthi Garden Phase II, Senneerkuppam,
                            Poonamallee, Near Bisleri Water Plant Chennai-600056
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            10
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Rajasthan
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Jaipur
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            81, 200 ft. By Pass, Near Ajmer Road, Heerapura,
                            Jaipur - 302021
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            11
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Chattisghar
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Raipur
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Mig 7Ring Road No-2 Kabir Nagar Raipur
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            12
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Assam
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Guwahati
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            HN-34, Kundil Nagar Basistha Chariali, Near Parbhat
                            Apartment Guwahati-781029
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            13
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            {" "}
                            Uttar Pradesh
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Lucknow{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            S-317, Transport Nagar, Near RTO Office Lucknow,
                            Uttar Pradesh 226012
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            14
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Madhyapradesh
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Indore{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            {" "}
                            284 AS-3 Scheme No.-78,vijay nagar Indore(MP)
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            15
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Gujarat{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Ahmedabad
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            23, Shivalik Residency, B/h Kadmavala Textiles, Near
                            Mony Hotel, Isanpur Ahmedabad
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            16
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Bihar
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Patna
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            PATNA Dr. A K Pandey (IPS) Malyanil, Buddha Colony,
                            Patna, Bihar-800001
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            17
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Andhra Pradesh
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Vishakapatnam
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Shop No.5, New Gajuwaka, Opp. High School Road,
                            Vishakapatnam, Andhra Pradesh-530026
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            18
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            West Bengal{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Kolkata{" "}
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            104 B T Road, Shed No. - 15/3, R I C I E, Block - 2
                            Near locknath mandir Bonhooghly Kolkata 700108
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            19
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Jharkand
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Jamshedpur
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Near Howrah Bridge New Kalimatiroad Sakchi
                            JAMSHEDPUR -831001
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            20
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Maharashtra
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Nagpur
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Shop-3 Kachore Complex Amrawati Road Waddhamna
                            Nagpur - 440023
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            21
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Orissa
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Bhubaneshwar
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            1st Floor Deltahouse Rajendra Nager Madhupatna
                            Cuttack , Bhuwaneshwar
                          </span>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="p-0">
                        <div class="flex items-center pl-10 xl:pl-24 h-20 bg-blueGray-50 border-l border-t border-b border-gray-100 rounded-tl-5xl rounded-bl-5xl">
                          <span class="text-lg font-heading font-medium">
                            22
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Punjab
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Chandigarh
                          </span>
                        </div>
                      </td>
                      <td class="p-0">
                        <div class="flex items-center justify-center p-5 h-20 text-center bg-blueGray-50 border-t border-b border-gray-100">
                          <span class="text-lg text-darkBlueGray-400 font-heading">
                            Shop-13, Gudev Nagar, Bhabat Road Zirakpur
                            Chandigarh, Punjab- 140603
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
