import React from "react";

export default function Sectiontwo() {
  return (
    <div>
      <section
        class="sm:py-16 px-24 white overflow-hidden bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -m-8">
            <div class="w-full md:w-1/2 xl:w-1/3 p-8">
              <div class="md:max-w-sm">
                <h2 class="mb-4 text-6xl md:text-6xl font-bold font-heading tracking-px-n leading-tight text-blue-800">
                  Services & Benefits
                </h2>
                <p class="text-gray-900 font-medium leading-relaxed">
                  {" "}
                  With our expertise and industry-leading practices, we ensure
                  that your post-sales operations are optimized for efficiency,
                  cost-effectiveness, and customer satisfaction.
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/2 xl:w-2/3 p-8">
              <div class="flex flex-wrap justify-end -m-4">
                <div class="w-full xl:w-auto p-4">
                  <div class="xl:max-w-sm h-full">
                    <div class="px-9 py-8 h-full rounded-xl shadow-7xl  bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600">
                      <div class="flex flex-col justify-between h-full">
                        <div>
                          <svg
                            class="mb-9"
                            width="51"
                            height="51"
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.6186 46.4724L36.6794 13.8038L4.01089 26.8647L11.6252 34.5008L30.1436 20.3397L15.9825 38.8581L23.6186 46.4724Z"
                              fill="#E2E8F0"
                            ></path>
                          </svg>
                          <h3 class="mb-5 text-3xl text-white font-bold leading-snug">
                            Product Returns Management
                          </h3>
                          <p class="mb-2 text-blue-200 font-medium">
                            We offer seamless handling of product returns,
                            including reverse pickup, inspection, and
                            assessment. Our dedicated team efficiently processes
                            returned items, ensuring prompt resolution and
                            customer satisfaction.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full xl:w-auto p-4">
                  <div class="xl:max-w-sm h-full">
                    <div class="px-9 py-8 h-full bg-blueGray-900 rounded-xl shadow-7xl  bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600">
                      <div class="flex flex-col justify-between h-full">
                        <div>
                          <svg
                            class="mb-14"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29.7 0H3.3C1.47675 0 0.0165 1.47675 0.0165 3.3L0 33L6.6 26.4H29.7C31.5232 26.4 33 24.9233 33 23.1V3.3C33 1.47675 31.5232 0 29.7 0ZM11.55 14.85H8.25V11.55H11.55V14.85ZM18.15 14.85H14.85V11.55H18.15V14.85ZM24.75 14.85H21.45V11.55H24.75V14.85Z"
                              fill="#E2E8F0"
                            ></path>
                          </svg>
                          <h3 class="mb-5 text-3xl text-white font-bold leading-snug">
                            Repair and Refurbishment
                          </h3>
                          <p class="mb-8 text-blue-200 font-medium">
                            Our experienced technicians are skilled in
                            diagnosing and repairing a wide range of products.
                            We provide comprehensive repair and refurbishment
                            services, restoring returned or damaged items to
                            like-new condition.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
