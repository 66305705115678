import React from "react";
import Image1 from "../../images/image1.jpg";
import { Link } from "react-router-dom";
import charImage from "../../images/new-02.png";
import charImageOne from "../../images/new-03.png";
import charImageTwo from "../../images/new-04.png";

export default function Section1() {
  return (
    <div>
      <section
        class="relative px-2 sm:px-6 sm:pb-10 pt-14  sm:pt-0 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <img
          class="absolute top-0 left-0"
          src="flaro-assets/images/headers/gradient4.svg"
          alt=""
        />

        <div class="overflow-hidden sm:pt-32 ">
          <div class="relative container px-4 mx-auto">
            <div class="flex flex-wrap -m-8">
              <div class="w-full md:w-1/2 lg:w-4/12 xl:w-6/12 p-8">
                <h1 class="mb-9 text-4xl md:text-6xl lg:text-6xl font-bold font-heading md:max-w-xl leading-none text-blue-800">
                  Sustainable technology <br />
                  lifecycle solutions{" "}
                </h1>
                <div>
                  <p class="mb-9 text-lg sm:text-xl text-gray-900 font-medium md:max-w-sm">
                    Transforming E-Waste into Sustainable Solutions
                  </p>
                  <div class="sm:mb-12 mb-20 md:inline-block">
                    <button
                      class=" px-2 py-4 sm:py-4 sm:px-6 w-full text-lg text-white font-semibold hover:text-black  rounded-xl focus:ring focus:ring-indigo-300 bg-blue-800 hover:bg-yellow-500 transition ease-in-out duration-200"
                      type="button"
                    >
                      Get Free Quote
                    </button>
                  </div>
                  <div class="flex flex-wrap items-center -m-1">
                    <div class="w-auto p-1"></div>
                  </div>
                </div>
              </div>
              <div class="hidden sm:block w-full  md:w-1/2 lg:w-8/12 xl:w-8/12 xl:absolute xl:right-0  xl:-bottom-24 sm:pb-44 ">
                <div class="flex flex-wrap justify-center items-center lg:justify-end">
                  <div class="w-auto lg:w-1/3 -m-20">
                    <div class="flex flex-wrap justify-end">
                      <div class="w-auto">
                        <img
                          class="mx-auto transform hover:-translate-y-2 transition ease-in-out duration-1000 rounded-lg h-44"
                          src={charImage}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-auto lg:w-1/3">
                    <div class="flex flex-wrap justify-end">
                      <div class="w-auto pb-3">
                        <Link to="/ewaste">
                          <img
                            class="mx-auto transform hover:-translate-y-2 transition ease-in-out duration-1000 rounded-lg h-44"
                            src={charImageTwo}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="w-auto">
                        <img
                          class="mx-auto  transform hover:-translate-y-2 transition ease-in-out duration-1000 rounded-lg h-44"
                          src={charImageOne}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-auto lg:w-1/3 p-3">
                    <div class="flex flex-wrap">
                      <div class="w-auto">
                        <img
                          class="mx-auto transform hover:-translate-y-2 transition ease-in-out duration-1000 h-96 rounded-lg"
                          src={Image1}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
