import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Icon from "../../images/logoo.png";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import Img1 from "../../images/navimg.png";
import Img2 from "../../images/it.png";
import Img3 from "../../images/metal.jpg";
import Img4 from "../../images/tower.jpg";
import Img5 from "../../images/revelogist.jpg";
import Img6 from "../../images/team3.png";
import Img7 from "../../images/team1.png";
import Img8 from "../../images/team2.png";
import Img9 from "../../images/navimg.png";
import Img10 from "../../images/playstore.png";

export default function Navbar() {
  const image_array = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
  ];

  const [imgs, setImg] = useState(9);

  function change_image(index) {
    setImg(index);
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropabout, setDropabout] = useState(false);
  const [dropevent, setDropevent] = useState(false);
  const [dropmedia, setDropmedia] = useState(false);

  const [imagePath, setImagePath] = useState("");
  const [showImage, setShowImage] = useState(false);

  const handleMouseOver = (imagePath) => {
    setImagePath(imagePath);
    setShowImage(true);
  };

  const handleMouseOut = () => {
    setImg(0);
  };

  const { id } = useParams();
  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="sticky top-0  z-50 bg-blue-800 "
      style={{ fontFamily: "Red Hat Display" }}
    >
      <section>
        <div class="relative flex lg:justify-end">
          <div class="flex w-full lg:w-auto h-24 lg:h-auto justify-between items-center py-4 md:py-0">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class=" block sm:hidden p-2 -mr-2 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class=" w-5 text-white" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>

            <div className="flex items-center justify-between z-20 md:hidden px-2">
              <div class="lg:hidden fixed top-0 left-0">
                {isMenuOpen && (
                  <SidebarWrap
                    key="first"
                    initial={{ x: -250, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -280, opacity: 1 }}
                    transition={{
                      duration: 0.36,
                      type: "tween",
                      ease: "easeInOut",
                    }}
                  >
                    <div class="absolute  w-full ">
                      <div
                        className="container  lg:w-4/5 block  "
                        style={{ fontFamily: "Red Hat Display" }}
                      >
                        <div>
                          <div className="flex gap-0 ">
                            <li class="dropdown inline ml-4  text-start  cursor-pointer    ">
                              <div class="dropdown-menu absolute hidden h-auto mt-3  z-50   ">
                                <ul class="block w-full bg-[#ffffff] shadow px-6 py-4 text-gray-900 rounded"></ul>
                              </div>
                            </li>

                            <Link
                              to="/profile"
                              className="flex py-6 text-gray-900  text-[20px] font-semibold rounded-lg shrink-0 "
                            >
                              <Link to="/">
                                <img className=" w-32" src={Icon} alt="" />
                              </Link>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <MenuWrap>
                        <nav>
                          <ul
                            class="space-y-2   text-left   text-[14px] text-gray-800"
                            style={{ fontFamily: "Red Hat Display" }}
                          >
                            <li className="nav-item pl-2 font-bold ">
                              <NavLink activeClassName="selected" exact to="/">
                                Home{" "}
                              </NavLink>
                            </li>

                            <li
                              className="nav-item pl-2 font-bold"
                              activeClassName="selected"
                            >
                              <NavLink to="/about">About</NavLink>
                            </li>

                            <li className="nav-item pl-2">
                              <div>
                                <li>
                                  <Link
                                    id="droptoggle"
                                    to="course"
                                    style={{
                                      backgroundColor: dropabout
                                        ? "#f5f5f5"
                                        : "#fff",
                                      color: dropabout
                                        ? "var(--brandColor)"
                                        : "text-teal-900",
                                    }}
                                    onClick={() => setDropabout(!dropabout)}
                                    exact
                                  >
                                    <b> Services </b>
                                    {dropabout ? (
                                      <MdKeyboardArrowUp />
                                    ) : (
                                      <MdKeyboardArrowDown />
                                    )}
                                  </Link>
                                  {dropabout && (
                                    <div className="dropdown_menu">
                                      <ul>
                                        {/* <li><Link to='ewaste' >E-Waste Recycling</Link></li> */}
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="ewaste"
                                            onMouseMove={() => {
                                              change_image(2);
                                            }}
                                          >
                                            E-Waste Recycling
                                          </Link>
                                        </li>
                                        <li>
                                          <Link class="font-bold" to="itad">
                                            IT Asset Management
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="/preciousmetalrecovery"
                                            onMouseEnter={() => {
                                              change_image(1);
                                            }}
                                          >
                                            Precious Metal Recovery
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="/telecomsiteoperations"
                                          >
                                            Telecom Site Operations
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="/logistics"
                                          >
                                            Reverse Logistics
                                          </Link>
                                        </li>

                                        <li>
                                          <Link class="font-bold" to="/epr">
                                            EPR Implementation
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="/datacenterservices"
                                          >
                                            Data Center Services
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            class="font-bold"
                                            to="/buyfromus"
                                          >
                                            Buy from us
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </li>
                              </div>
                            </li>

                            <li className="nav-item pl-2 font-bold">
                              <NavLink to="contact" activeClassName="selected">
                                Contact
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                      </MenuWrap>
                    </div>
                    <div>
                      {imgs == Img1 && (
                        <div class="w-full lg:w-1/2 lg:pl-3 lg:mt-6 order-first lg:order-last">
                          <NavLink to="/About"></NavLink>
                        </div>
                      )}
                    </div>
                  </SidebarWrap>
                )}

                <div>
                  <AnimatePresence>
                    {isMenuOpen && (
                      <BackdropWrap
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                          duration: 0.36,
                          type: "tween",
                          ease: "easeInOut",
                        }}
                        onClick={() => setIsMenuOpen(false)}
                      />
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <Link to="/">
                <img src={Icon} alt="" className=" w-40 md:hidden" />
              </Link>
            </div>
          </div>
        </div>

        {/* --------------------------------------------------laptop-------------------------------------------------------- */}

        {/* topnav */}
        <div>
          <nav className=" hidden sm:block">
            <div class=" flex h-12 py-3 px-6 items-center justify-between bg-gray-800">
              <div class="w-auto">
                <a class="inline-flex mr-6 items-center text-yellow-500">
                  <svg
                    fill="currentColor"
                    width="20px"
                    height="20px"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                  </svg>

                  <span class="hidden lg:block ml-2 text-sm font-bold">
                    Nelamangala - 561123 , Bangalore, Karnataka
                  </span>
                </a>
                <a class="inline-flex mr-6 items-center text-yellow-500">
                  <svg
                    fill="currentColor"
                    width="20px"
                    height="20px"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                  </svg>

                  <span class="hidden lg:block ml-2 text-sm font-bold">
                    Gerupalya, Kumbalagodu Indl Area, Bengaluru, 560074
                  </span>
                </a>
                <a class="inline-flex items-center text-yellow-500">
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span class="hidden lg:block ml-2 text-sm font-bold">
                    info@terranova-eco.com
                  </span>
                </a>
              </div>
              <a
                href=" https://accounts.zoho.in/signin?servicename=TeamDrive&signupurl=https://www.zoho.com/workdrive/signup.html"
                target="_blank"
              >
                <button class=" text-sm  ml-96 font-bold font-body border-2 border-gray-200 border-opacity-50 rounded-full py-1 px-6 tracking-wide hover:border-gray-200">
                  <span to="contact" class="block mt-px text-yellow-500">
                    Employee Login
                  </span>
                </button>
              </a>
              <a
                href="  https://accounts.zoho.in/signin?servicename=zohopeople&signupurl=https://www.zoho.com/people/signup.html"
                target="_blank"
              >
                <button class="text-sm mr-16 font-bold font-body border-2 border-gray-200 border-opacity-50 rounded-full py-1 px-6 tracking-wide hover:border-gray-200">
                  <span class="block mt-px text-yellow-500">Client Login</span>
                </button>
              </a>
            </div>
          </nav>
        </div>

        {/* ----------------------------------------------------- */}

        <div class=" hidden sm:block bg-blue-800 ">
          <div class="px-6 md:px-6 sm:py-3">
            <nav class="flex justify-between">
              <div class="flex w-full items-center">
                <Link to="/" class="inline-block">
                  <img class="h-14" src={Icon} alt="" />
                </Link>
                <ul class="hidden md:flex px-4 ml-40">
                  <li class="mr-14 mt-1">
                    <Link
                      to="/"
                      class="font-semibold text-lg text-white hover:text-yellow-500"
                    >
                      Home
                    </Link>
                  </li>
                  <Link
                    to="/about"
                    className="mr-14 mt-1 font-semibold text-white hover:text-yellow-500 text-lg"
                  >
                    About
                  </Link>

                  <div className="dropdown dropdown-hover flex mr-14">
                    <Link
                      to="#"
                      class="flex items-center font-semibold text-white hover:text-yellow-500"
                    >
                      <span class="mr-2 text-lg mt-1">Services</span>
                      <svg
                        width="8"
                        height="5"
                        viewbox="0 0 8 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </Link>

                    <div class=" divide-x-2 grid grid-cols-3 dropdown-content menu absolute  top-8 p-2 shadow bg-white text-gray-900 font-semibold rounded-box w-[650px]">
                      <div tabIndex={0} className=" ">
                        <Wrapper>
                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/ewaste"
                                onMouseEnter={() => {
                                  change_image(8);
                                }}
                                onMouseOut={handleMouseOut}
                                class=" flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                              >
                                E-Waste Recycling
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>

                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/itad"
                                onMouseEnter={() => {
                                  change_image(1);
                                }}
                                onMouseOut={handleMouseOut}
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                              >
                                IT Asset Management
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>

                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/preciousmetalrecovery"
                                onMouseEnter={() => {
                                  change_image(2);
                                }}
                                onMouseOut={handleMouseOut}
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                              >
                                Precious Metal Recovery
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>
                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/telecomsiteoperations"
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                                onMouseEnter={() => {
                                  change_image(3);
                                }}
                                onMouseOut={handleMouseOut}
                              >
                                Telecom Site Operations
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>
                        </Wrapper>
                      </div>

                      <div tabIndex={0} className="">
                        <Wrapper>
                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/logistics"
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                                onMouseEnter={() => {
                                  change_image(4);
                                }}
                                onMouseOut={handleMouseOut}
                              >
                                Reverse Logistics
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>

                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/epr"
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                                onMouseEnter={() => {
                                  change_image(5);
                                }}
                                onMouseOut={handleMouseOut}
                              >
                                EPR Implementation
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>

                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/datacenterservices"
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                                onMouseEnter={() => {
                                  change_image(6);
                                }}
                                onMouseOut={handleMouseOut}
                              >
                                Data Center Services
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>

                          <li class="rounded-sm relative">
                            <button class="w-full text-left flex items-center outline-none focus:outline-none bg-white">
                              <Link
                                to="/buyfromus"
                                class="pr-1 flex-1 text-gray-900 hover:text-blue-800 hover:bg-gray-200 text-md"
                                onMouseEnter={() => {
                                  change_image(7);
                                }}
                                onMouseOut={handleMouseOut}
                              >
                                Buy from us
                              </Link>
                              <span class="mr-auto"></span>
                            </button>
                          </li>
                        </Wrapper>
                      </div>

                      <div tabIndex={0} className="">
                        <Wrapper>
                          <img Src={image_array[imgs]} alt="" />

                          {/* <img Src={Image2} id='ITAD' alt=''/> */}
                        </Wrapper>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/eprr"
                    className="mr-14 mt-1 font-semibold text-white hover:text-yellow-500 text-lg"
                  >
                    EPR{" "}
                  </Link>

                  <Link
                    to="/contact"
                    className="mr-14 mt-1 font-semibold text-white hover:text-yellow-500 text-lg"
                  >
                    Contact
                  </Link>
                </ul>
              </div>
              <div className="hidden sm:block sm:pt-2">
                <div class=" flex mr-10  items-center">
                  <div class="flex flex-shrink-0 items-center justify-center w-10 h-10 rounded-full bg-blue-800">
                    <svg
                      viewBox="0 0 24 24"
                      fill="white"
                      height="10em"
                      width="10em"
                    >
                      <path d="M20 10.999h2C22 5.869 18.127 2 12.99 2v2C17.052 4 20 6.943 20 10.999z" />
                      <path d="M13 8c2.103 0 3 .897 3 3h2c0-3.225-1.775-5-5-5v2zm3.422 5.443a1.001 1.001 0 00-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 00.043-1.391L6.859 3.513a1 1 0 00-1.391-.087l-2.17 1.861a1 1 0 00-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 00.648-.291l1.86-2.171a1 1 0 00-.086-1.391l-4.064-3.696z" />
                    </svg>{" "}
                  </div>
                  <span class="text-xl font-semibold text-white ml-2">
                    +18003093201
                  </span>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
}

const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }

  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #1034a6;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: #88448a;
        color: white;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
