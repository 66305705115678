import React from "react";
import ImageOne from "../../../images/image3.jpg";

export default function Sectionfour() {
  return (
    <div>
      <section
        class="relative pt-28 pb-32 bg-blue-700 overflow-hidden px-14"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <img
          class="absolute top-0 left-1/2 transform -translate-x-1/2 opacity-10"
          src={ImageOne}
          alt=""
        />
        <div class="relative z-10 container px-4 mx-auto">
          <div class="mb-24 border-b border-white border-opacity-10">
            <h2 class="pb-8 text-2xl md:text-4xl xl:text-6xl text-white font-bold tracking-px-n leading-none max-w-4xl">
              Why Choose Terranova Eco for EPR(Extended Producer
              Responsibility)?
            </h2>
          </div>
          <div class="flex flex-wrap -m-8">
            <div class="w-full md:w-1/3 p-8">
              <p class="text-gray-900 text-left pl-5 py-4 font-medium leading-relaxed text-lg border bg-white rounded-xl px-2">
                {" "}
                Our EPR experts collaborate with your design and engineering
                teams to identify opportunities for product and packaging
                optimization
              </p>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <p class="text-gray-900 text-left pl-5 py-4 font-medium leading-relaxed text-lg border bg-white rounded-xl px-2">
                we reduce the need for raw material extraction, conserve energy,
                and reduce greenhouse gas emissions associated with
                manufacturing processes.
              </p>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <p class="text-gray-900 text-left pl-5 py-4 font-medium leading-relaxed text-lg border bg-white rounded-xl px-2">
                By actively participating in responsible product management and
                promoting recycling, you enhance your brand reputation .
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
