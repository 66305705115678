import React from "react";
import backgroundImageOne from "../../../images/1.webp";
import { Link } from "react-router-dom";

export default function Sectionone() {
  return (
    <div>
      <section
        class="py-28 bg-white bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
        style={{ backgroundImage: `url(${backgroundImageOne})` }}
      >
        <div
          class="container px-4 mx-auto"
          style={{ fontFamily: "Red Hat Display" }}
        >
          <div
            class="px-12 pt-12 pb-9 lg:ml-14 md:max-w-lg bg-opacity-80 rounded-xl  bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600"
            style={{ backdropFilter: "10px" }}
          >
            <p class="mb-7  max-w-max px-3 py-1.5 text-sm text-white font-semibold uppercase border border-gray-100 rounded-md">
              Infrastructure
            </p>
            <h2 class="mb-4 text-3xl md:text-5xl text-white font-bold font-heading tracking-px-n leading-tight">
              IT Asset Management Expert
            </h2>
            <p class="mb-11 text-lg font-medium leading-normal text-gray-100">
              Asset Remarketing, Asset Refurbishment, Secure Data Sanitization,
              Logistics & Asset Tracking
            </p>
            <Link
              to="/"
              class="inline-flex flex-wrap items-center text-white hover:text-gray-200"
            >
              <span class="mr-2 font-semibold leading-normal">
                Read Full Article
              </span>
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
