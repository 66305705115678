import React from "react";
import backgroundImageOne from "../../../images/order.webp";

export default function Sectionone() {
  return (
    <div>
      <section
        class="py-28 bg-white bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
        style={{ backgroundImage: `url(${backgroundImageOne})` }}
      >
        <div
          class="container px-4 mx-auto"
          style={{ fontFamily: "Red Hat Display" }}
        >
          <div
            class="px-12 pt-12 pb-9 ml-14 md:max-w-lg bg-opacity-80 rounded-xl  bg-gradient-to-br from-blue-800 via-blue-700 to-blue-600"
            style={{ backdropFilter: "10px" }}
          >
            <p class="mb-7 font-sans max-w-max px-3 py-1.5 text-sm text-white font-semibold uppercase border border-gray-700 rounded-md">
              Buy From Us
            </p>
            <h2 class="mb-4 text-3xl md:text-4xl text-white font-bold font-heading tracking-px-n leading-tight">
              {" "}
              Buy Sustainable and Budget-Friendly at Terranova!
            </h2>
            <p class="mb-4 text-lg font-medium leading-normal text-gray-100">
              At Terranova, we are proud to offer a wide range of high-quality
              refurbished electronics, including systems, keyboards, laptops,
              processors, and more. Embrace eco-friendly shopping without
              compromising on performance or budget. Our skilled technicians
              carefully restore and upgrade pre-owned electronics, ensuring they
              meet stringent quality standards and function like new.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
