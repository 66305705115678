import React from "react";
import backgroundImageOne from "../../images/bgimage.jpg";

export default function Section4() {
  return (
    <div>
      <section
        class="bg-blue-50 bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden"
        style={{ backgroundImage: `url(${backgroundImageOne})` }}
      >
        <div
          class="sm:py-32 py-16 px-2 sm:px-0 bg-black bg-opacity-60"
          style={{ backdropFilter: "blur(9px)" }}
        >
          <div
            class="container px-4 mx-auto"
            style={{ fontFamily: "Red Hat Display" }}
          >
            <div class="text-center max-w-3xl mx-auto">
              <p class="mb-9 font-sans text-sm text-white font-semibold uppercase tracking-px">
                👋Join TerraNova Today
              </p>
              <h2 class="mb-11 text-4xl md:text-6xl xl:text-10xl text-white font-bold text-center tracking-px-n leading-none">
                Work with us and experience &amp; the best of e-Recycling
                industry
              </h2>
              <div class="md:inline-block">
                <button
                  class="py-4 px-6 w-full text-white hover:text-black font-semibold  rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-blue-800 hover:bg-yellow-500 transition ease-in-out duration-200"
                  type="button"
                >
                  Connect with us.
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
