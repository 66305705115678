import React from "react";

export default function Contact() {
  return (
    <div>
      <section
        class="relative py-10 sm:py-16 sm:px-24 bg-white overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <img
          class="absolute top-0 left-0"
          src="saturn-assets/images/contact/light-left-blue.png"
          alt=""
        />
        <img
          class="absolute bottom-0 right-0 -mb-20"
          src="saturn-assets/images/contact/light-orange-right.png"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-2xl mx-auto mb-10 text-center">
            <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-800 bg-blue-50 rounded-full">
              READY TO SUPPORT
            </span>
            <h1 class="font-heading text-3xl sm:text-6xl font-bold text-blue-800 mb-4">
              <span>Get in touch with us</span>
            </h1>
          </div>
          <div class="max-w-5xl mx-auto">
            <div class="flex flex-wrap justify-center -mx-4">
              <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div class="max-w-lg mx-auto h-full bg-white border-2 border-blue-50 rounded-3xl">
                  <div class="pt-4 px-4 pb-9 border-b border-blue-100">
                    <div class="relative mb-10">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497291.00825454213!2d77.332571!3d13.156975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae2974b84de649%3A0x552ec52526698301!2sTerranova%20Eco%20Management%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1687246484574!5m2!1sen!2sin"
                        width="450"
                        height="300"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>{" "}
                      <div class="absolute bottom-0 right-0 -mb-12 mr-4">
                        <div class="relative w-24 h-24 flex items-center justify-center"></div>
                      </div>
                    </div>
                    <div class="px-4">
                      <h4 class="text-2xl font-semibold text-blue-800">
                        Visit our Registered office and Recycling Plant
                      </h4>
                    </div>
                  </div>
                  <div class="py-8 px-6">
                    <div class="flex mb-6 items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          fill="white"
                          width="40px"
                          height="40px"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                        </svg>
                      </div>
                      <span class="text-lg text-gray-900">
                        No. 59/263/1/19/5, Ground, First and Second Floor,
                        Survey No. 19/5, 19/8, 19/9 and 19/11, NH4,
                        Malonagathihalli, T Begur, Nelamangala, Bengaluru -
                        561123
                      </span>
                    </div>
                    <div class="flex mb-6 items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          fill="white"
                          width="30px"
                          height="30px"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z" />
                        </svg>
                      </div>
                      <span class="text-lg text-gray-900">+18003093201</span>
                    </div>
                    <div class="flex items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      <span class="text-lg text-gray-900">
                        guha@terranova-eco.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 sm:px-4">
                <div class="max-w-lg mx-auto h-full bg-white border-2 border-blue-50 rounded-3xl">
                  <div class="pt-4 sm:px-4 pb-9 border-b border-blue-100">
                    <div class="relative mb-10 rounded-2xl ">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.3856429295865!2d77.438546!3d12.882906!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae39016d163e23%3A0x19f6086cc8ee0fa8!2sTerranova%20Eco%20Management%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1687245901039!5m2!1sen!2sin"
                        width="450"
                        height="300"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                      <div class="absolute bottom-0 right-0 -mb-12 mr-4">
                        <div class="relative w-24 h-24 flex items-center justify-center"></div>
                      </div>
                    </div>

                    <div class="px-4">
                      <h4 class="text-2xl font-semibold text-blue-800">
                        Visit our IT Repair and Refurbishment facility
                      </h4>
                    </div>
                  </div>
                  <div class="py-8 px-6">
                    <div class="flex mb-6 items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          fill="white"
                          width="40px"
                          height="40px"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                        </svg>
                      </div>
                      <span class="text-lg text-gray-900">
                        Plot No. 10, Ground, First and Second Floor, Vaishnodevi
                        Lush Greens Industrial Estate, Gerupalya, Kumbalagodu
                        Indl Area, Bengaluru - 560074
                      </span>
                    </div>
                    <div class="flex mb-6 items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          fill="white"
                          width="30px"
                          height="30px"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z" />
                        </svg>
                      </div>
                      <span class="text-lg text-gray-900">+18003093201</span>
                    </div>
                    <div class="flex items-center">
                      <div class="flex flex-shrink-0 mr-5 items-center justify-center w-12 h-12 rounded-full bg-blue-800">
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                          />
                        </svg>{" "}
                      </div>
                      <span class="text-lg text-gray-900">
                        suhas@terranova-eco.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
