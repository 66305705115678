import React from "react";
import { Link } from "react-router-dom";
import Line from "../../images/line1.jpg";
import Line1 from "../../images/line2.jpg";
import Line2 from "../../images/line3.jpg";
import Line3 from "../../images/line4.jpg";
import Line4 from "../../images/line5.jpg";

export default function Section6() {
  return (
    <div>
      <section
        class="py-8 mb-20 bg-white"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl mx-auto mb-10 text-center">
            <h2 class="text-2xl md:text-4xl font-bold font-heading tracking-px-n leading-tight mb-5 text-blue-800 text-center">
              Dismantling Line
            </h2>
          </div>
          <div class="flex flex-wrap -mx-4 -mb-20">
            <div class="w-full lg:w-1/3 px-4 mb-5 ">
              <Link to="/" class="hover:underline">
                <div class="relative flex h-80 overflow-hidden">
                  <img class="w-full h-80 rounded-lg " src={Line} alt="" />
                </div>
              </Link>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-5">
              <Link to="/" class="hover:underline">
                <div class="relative flex h-80 overflow-hidden">
                  <img class="w-full h-80 rounded-lg " src={Line1} alt="" />
                </div>
              </Link>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-5">
              <Link to="/" class="hover:underline">
                <div class="relative flex h-80 overflow-hidden">
                  <img class="w-full h-80 rounded-lg" src={Line2} alt="" />
                </div>
              </Link>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-5">
              <Link to="/" class="hover:underline">
                <div class="relative flex h-80 overflow-hidden">
                  <img class="w-full h-80 rounded-lg" src={Line3} alt="" />
                </div>
              </Link>
            </div>
            <div class="w-full lg:w-1/3 px-4 mb-5">
              <Link to="/" class="hover:underline">
                <div class="relative flex h-80 overflow-hidden">
                  <img class="w-full h-80 rounded-lg" src={Line4} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
