import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 py-12 px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#00cc00" />
                        <path
                          d="M250 100 L400 250 L250 400 L100 250 Z"
                          fill="#ffffff"
                        />
                        <circle cx="250" cy="250" r="120" fill="#00cc00" />
                        <circle cx="250" cy="250" r="70" fill="#ffffff" />
                        <path
                          d="M250 110 L390 250 L250 390 L110 250 Z"
                          fill="#00cc00"
                        />
                        <rect
                          x="240"
                          y="235"
                          width="20"
                          height="30"
                          fill="#ffffff"
                        />
                        <rect
                          x="250"
                          y="240"
                          width="40"
                          height="20"
                          fill="#ffffff"
                        />
                        <rect
                          x="235"
                          y="250"
                          width="30"
                          height="40"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      We are committed to environmental sustainability and
                      responsible disposal practices.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="150"
                          fill="#6495ed"
                        />
                        <rect
                          x="170"
                          y="220"
                          width="160"
                          height="110"
                          fill="#ffffff"
                        />
                        <rect
                          x="200"
                          y="250"
                          width="100"
                          height="50"
                          fill="#6495ed"
                        />
                        <rect
                          x="210"
                          y="220"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                        <rect
                          x="210"
                          y="260"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                        <rect
                          x="210"
                          y="300"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Maximize the value of your returned or excess inventory
                      through our asset recovery and resale services.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="150"
                          fill="#ffcc00"
                        />
                        <rect
                          x="170"
                          y="220"
                          width="160"
                          height="110"
                          fill="#ffffff"
                        />
                        <circle cx="250" cy="250" r="30" fill="#ffcc00" />
                        <line
                          x1="230"
                          y1="250"
                          x2="270"
                          y2="250"
                          stroke="#ffcc00"
                          stroke-width="3"
                        />
                        <line
                          x1="250"
                          y1="230"
                          x2="250"
                          y2="270"
                          stroke="#ffcc00"
                          stroke-width="3"
                        />
                        <line
                          x1="230"
                          y1="230"
                          x2="270"
                          y2="270"
                          stroke="#ffcc00"
                          stroke-width="3"
                        />
                        <line
                          x1="230"
                          y1="270"
                          x2="270"
                          y2="230"
                          stroke="#ffcc00"
                          stroke-width="3"
                        />
                        <rect
                          x="190"
                          y="320"
                          width="120"
                          height="40"
                          fill="#ffcc00"
                        />
                        <text
                          x="250"
                          y="345"
                          text-anchor="middle"
                          font-size="20"
                          font-family="Arial"
                          fill="#ffffff"
                        >
                          Support
                        </text>
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Reverse logistics solutions encompass efficient management
                      of warranty claims and service support.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
