import React from "react";
import Section1 from "../facilities/section1";
import Section2 from "../facilities/section2";
import Section3 from "../facilities/section3";
import Section4 from "../facilities/section4";
import Section5 from "../facilities/section5";
import Section6 from "../facilities/section6";
import Section7 from "../facilities/section7";
import Section8 from "../facilities/section8";

export default function Index() {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section3 />
      <Section8 />
    </div>
  );
}
