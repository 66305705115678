import React from "react";
import Computer from "../../../images/destop.jpg";
import Laptop from "../../../images/laptop.webp";
import Keybord from "../../../images/key.webp";
import Mouse from "../../../images/mother.webp";
import { Link } from "react-router-dom";

export default function Sectiontwo() {
  return (
    <div>
      <section
        class="py-24 2xl:py-10 bg-blueGray-100"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <h2 class="mb-14 xl:mb-5 text-9xl xl:text-6xl font-semibold text-center text-blue-800">
            Products
          </h2>
          <div class="flex items-center">
            <Link
              to="/"
              class="hidden md:block pr-6 hover:text-darkBlueGray-400"
            >
              <svg
                width="8"
                height="12"
                viewbox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.21015 10.4594C7.59661 10.8128 7.59661 11.3841 7.21015 11.7349C6.82369 12.0871 6.19929 12.0897 5.81282 11.7349L0.289847 6.63794C-0.0966174 6.28579 -0.0966173 5.71584 0.289847 5.36108L5.81282 0.264109C6.19785 -0.0880364 6.82369 -0.0880364 7.21015 0.264109C7.59662 0.617558 7.59662 1.18882 7.21015 1.53966L2.68073 6.00147L7.21015 10.4594Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
            <div class="flex flex-wrap -mx-0 text-center">
              <div class="w-full sm:w-1/2 xl:w-1/4 px-3 mb-14 xl:mb-0">
                <div class="xl:flex xl:items-center p-10 xl:py-20 bg-white rounded-3xl">
                  <Link to="/" class="block mx-auto max-w-max">
                    <img class="h-40 object-cover" src={Computer} alt="" />
                  </Link>
                </div>
                <Link to="/">
                  <p class="mb-4 text-xl leading-8 font-heading font-medium hover:underline">
                    Computer
                  </p>
                </Link>
                <p class="text-xl text-blue-500 font-heading font-medium tracking-tighter">
                  <span class="text-base pr-2">$</span>
                  <span>544.90</span>
                </p>
              </div>
              <div class="w-full sm:w-1/2 xl:w-1/4 px-3 mb-14 xl:mb-0">
                <div class="xl:flex xl:items-center p-10 xl:py-20  bg-white rounded-3xl">
                  <Link to="/" class="block mx-auto max-w-max">
                    <img class="h-40 object-cover" src={Laptop} alt="" />
                  </Link>
                </div>
                <Link to="/">
                  <p class="mb-4 text-xl leading-8 font-heading font-medium hover:underline">
                    Laptop's
                  </p>
                </Link>
                <p class="text-xl text-blue-500 font-heading font-medium tracking-tighter">
                  <span class="text-base pr-2">$</span>
                  <span>444.90</span>
                </p>
              </div>
              <div class="w-full sm:w-1/2 xl:w-1/4 px-3 mb-14 sm:mb-0">
                <div class="xl:flex xl:items-center p-10 xl:py-20  bg-white rounded-3xl">
                  <Link to="/" class="block mx-auto max-w-max">
                    <img class="h-40 object-cover" src={Keybord} alt="" />
                  </Link>
                </div>
                <Link to="/">
                  <p class="mb-4 text-xl leading-8 font-heading font-medium hover:underline">
                    Keyboards
                  </p>
                </Link>
                <p class="text-xl text-blue-500 font-heading font-medium tracking-tighter">
                  <span class="text-base pr-2">$</span>
                  <span>20.59</span>
                </p>
              </div>
              <div class="w-full sm:w-1/2 xl:w-1/4 px-3">
                <div class="xl:flex xl:items-center p-10 xl:py-20  bg-white rounded-3xl">
                  <Link to="/" class="block mx-auto max-w-max">
                    <img class="h-40 object-cover" src={Mouse} alt="" />
                  </Link>
                </div>
                <Link to="/">
                  <p class="mb-4 text-xl leading-8 font-heading font-medium hover:underline">
                    Mother boards
                  </p>
                </Link>
                <p class="text-xl text-blue-500 font-heading font-medium tracking-tighter">
                  <span class="text-base pr-2">$</span>
                  <span>120.90</span>
                </p>
              </div>
            </div>
            <Link
              to="/"
              class="hidden md:block pl-6 hover:text-darkBlueGray-400"
            >
              <svg
                width="8"
                height="12"
                viewbox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.289849 1.54064C-0.0966146 1.18719 -0.0966145 0.615928 0.28985 0.265087C0.676314 -0.087058 1.30071 -0.0896664 1.68718 0.265087L7.21015 5.36206C7.59662 5.71421 7.59662 6.28416 7.21015 6.63892L1.68718 11.7359C1.30215 12.088 0.676312 12.088 0.289848 11.7359C-0.0966159 11.3824 -0.0966159 10.8112 0.289848 10.4603L4.81927 5.99853L0.289849 1.54064Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
          </div>
          <div class="md:hidden flex justify-center mt-20">
            <Link to="/" class="mx-14 hover:text-darkBlueGray-400">
              <svg
                width="8"
                height="12"
                viewbox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.21015 10.4594C7.59661 10.8128 7.59661 11.3841 7.21015 11.7349C6.82369 12.0871 6.19929 12.0897 5.81282 11.7349L0.289847 6.63794C-0.0966174 6.28579 -0.0966173 5.71584 0.289847 5.36108L5.81282 0.264109C6.19785 -0.0880364 6.82369 -0.0880364 7.21015 0.264109C7.59662 0.617558 7.59662 1.18882 7.21015 1.53966L2.68073 6.00147L7.21015 10.4594Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
            <Link to="/" class="mx-14 hover:text-darkBlueGray-400">
              <svg
                width="8"
                height="12"
                viewbox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.289849 1.54064C-0.0966146 1.18719 -0.0966145 0.615928 0.28985 0.265087C0.676314 -0.087058 1.30071 -0.0896664 1.68718 0.265087L7.21015 5.36206C7.59662 5.71421 7.59662 6.28416 7.21015 6.63892L1.68718 11.7359C1.30215 12.088 0.676312 12.088 0.289848 11.7359C-0.0966159 11.3824 -0.0966159 10.8112 0.289848 10.4603L4.81927 5.99853L0.289849 1.54064Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
