import React from "react";
import SectionOne from "./sectionone";
import SectionTwo from "./sectiontwo";
import SectionThree from "./sectionthree";
import SectionFive from "./sectionfive";

export default function Index() {
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFive />
    </div>
  );
}
