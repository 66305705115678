import React from "react";
import Build1 from "../../images/build1.jpg";
import Build2 from "../../images/build2.webp";

export default function Section4() {
  return (
    <div>
      <section class="py-12 md:py-10" style={{ fontFamily: "Red Hat Display" }}>
        <div class="container mx-auto px-4">
          <div class="mx-16">
            <h2 class="text-4xl md:text-4xl xl:text-10xl text-center font-bold font-heading tracking-px-n mb-5 leading-none text-blue-800">
              Overview of Terranova Recycling Facility
            </h2>
            <p class="text-lg text-gray-700 font-semibold">
              {" "}
              Set up on the outskirts of Bangalore on Bengaluru Mumbai Highway.
              1.5 Acre Property with a Built up Area of 150000 Sq Ft. Proposed
              Processing Capacity of 7800 MT/A.
            </p>
            <p class="text-lg text-gray-700 mb-10 font-semibold">
              {" "}
              CCTV Coverage, Secure rooms for storage of sensitive materials,
              Access control system. The building also consists of Secure
              storage rooms for storage and processing of sensitive materials.
            </p>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
              <div class="flex flex-col max-w-md mx-auto lg:max-w-none px-8 pt-8 h-full bg-blue-50 rounded-4xl">
                <div class="max-w-xl mx-auto text-center">
                  <h3 class="font-semibold text-3xl text-blue-800 mb-4">
                    Main Building 1
                  </h3>
                  <p class="text-lg text-gray-700 mb-5 font-semibold">
                    Consists of Warehouse, Segregation, Dismantling, PCB
                    Recycling, CFL recycling,{" "}
                  </p>
                </div>
                <img class="mx-20" src={Build1} alt="" />
                <div className="mt-4 ml-10 px-4">
                  <div class="w-auto p-2">
                    <h3 class="text-xl font-semibold mb-2">
                      The following are the List of Machineries in the Recycling
                      Unit :-
                    </h3>
                  </div>
                  <ul class="mb-7 ">
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">Segregation & Dismantling</span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Cable Recycling & PCB Recycling
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Compressor Cutting, Radiator Cutting ( For Ref & ACs)
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">Aluminum Smelting</span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Copper Smelting –Precious Metal Recovery which Includes{" "}
                        <br />
                        Alkaline Stripping, Electrolysis
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">CFL Recycling</span>
                    </li>
                    <li class="flex items-center">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        In-house Testing Laboratory
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
              <div class="flex flex-col max-w-md mx-auto lg:max-w-none px-8 pt-8 h-full bg-blue-50 rounded-4xl">
                <div class="max-w-xl mx-auto text-center">
                  <h3 class="font-semibold text-3xl text-blue-800 mb-4">
                    Building 2
                  </h3>
                  <p class="text-lg text-gray-700 mb-5 font-semibold">
                    Copper Smelting, Aluminum Smelting, Alkaline Stripping,{" "}
                    <br />
                    Electrolysis{" "}
                  </p>
                </div>
                <img class="mx-20" src={Build2} alt="" />
                <div className="mt-4 ml-10 px-4">
                  <div class="w-auto p-2">
                    <h3 class="text-xl font-semibold mb-2">
                      Pollution Control system Includes :-
                    </h3>
                  </div>
                  <ul class="mb-7">
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Dust Collector system with Cyclone and Bag House for
                        <br /> Mechanical Process.
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">Scrubber for Smelting</span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Fume Scrubber for Alkaline Process
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Fume Scrubber for Acid Process
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Sewage Treatment Plant for domestic Wastewater
                      </span>
                    </li>
                    <li class="flex items-center mb-3">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">
                        Wastewater Treatment plant for chemical process
                      </span>
                    </li>
                    <li class="flex items-center">
                      <svg
                        class="mr-2.5"
                        width="20"
                        height="20"
                        viewbox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10" r="10" fill="#1569C7"></circle>
                        <path
                          d="M5.91602 10.5833L8.24935 12.9166L14.0827 7.08325"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <span class="font-medium">Security System includes</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
