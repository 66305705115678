import React from "react";

export default function Sectionthree() {
  return (
    <div>
      <section
        class="bg-white overflow-hidden text-gray-800 py-12 px-12"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-8 mx-auto">
          <div class="flex flex-wrap items-center -m-8">
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <rect
                          x="150"
                          y="200"
                          width="200"
                          height="150"
                          fill="#6495ed"
                        />
                        <rect
                          x="170"
                          y="220"
                          width="160"
                          height="110"
                          fill="#ffffff"
                        />
                        <rect
                          x="200"
                          y="250"
                          width="100"
                          height="50"
                          fill="#6495ed"
                        />
                        <rect
                          x="210"
                          y="220"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                        <rect
                          x="210"
                          y="260"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                        <rect
                          x="210"
                          y="300"
                          width="80"
                          height="30"
                          fill="#ffffff"
                        />
                        <line
                          x1="150"
                          y1="200"
                          x2="170"
                          y2="220"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="350"
                          y1="200"
                          x2="330"
                          y2="220"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="150"
                          y1="350"
                          x2="170"
                          y2="370"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="350"
                          y1="350"
                          x2="330"
                          y2="370"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="170"
                          y1="330"
                          x2="330"
                          y2="330"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="250"
                          y1="220"
                          x2="250"
                          y2="200"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <line
                          x1="250"
                          y1="370"
                          x2="250"
                          y2="350"
                          stroke="#6495ed"
                          stroke-width="4"
                        />
                        <circle cx="250" cy="250" r="20" fill="#6495ed" />
                        <circle cx="250" cy="250" r="40" fill="#ffffff" />
                        <circle cx="250" cy="250" r="60" fill="#6495ed" />
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Thoroughly clean the data center facility, following
                      environmental regulations.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#ff0000" />
                        <circle cx="250" cy="250" r="160" fill="#ffffff" />
                        <path d="M250 120 L330 330 L170 330 Z" fill="#ff0000" />
                        <line
                          x1="250"
                          y1="150"
                          x2="250"
                          y2="300"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="220"
                          y1="190"
                          x2="280"
                          y2="190"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="220"
                          y1="230"
                          x2="280"
                          y2="230"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="220"
                          y1="270"
                          x2="280"
                          y2="270"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <text
                          x="250"
                          y="400"
                          text-anchor="middle"
                          font-size="40"
                          font-weight="bold"
                          font-family="Arial"
                          fill="#ff0000"
                        >
                          SAFETY
                        </text>
                        <text
                          x="250"
                          y="450"
                          text-anchor="middle"
                          font-size="30"
                          font-family="Arial"
                          fill="#ffffff"
                        >
                          GUIDELINES
                        </text>
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      {" "}
                      Handling Equipment removal with proper procedures and
                      safety guidelines.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 p-8">
              <div class="md:max-w-sm">
                <div class="flex flex-wrap items-center -m-3">
                  <div class="w-auto p-3">
                    <div class="flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <circle cx="250" cy="250" r="200" fill="#ffcc00" />
                        <circle cx="250" cy="250" r="160" fill="#ffffff" />
                        <circle cx="250" cy="250" r="120" fill="#ffcc00" />
                        <circle cx="250" cy="250" r="80" fill="#ffffff" />
                        <circle cx="250" cy="250" r="40" fill="#ffcc00" />
                        <line
                          x1="250"
                          y1="150"
                          x2="250"
                          y2="230"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="190"
                          y1="190"
                          x2="310"
                          y2="190"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="190"
                          y1="210"
                          x2="310"
                          y2="210"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <line
                          x1="190"
                          y1="230"
                          x2="310"
                          y2="230"
                          stroke="#ffffff"
                          stroke-width="4"
                        />
                        <text
                          x="250"
                          y="400"
                          text-anchor="middle"
                          font-size="40"
                          font-weight="bold"
                          font-family="Arial"
                          fill="#ffcc00"
                        >
                          GOALS
                        </text>
                      </svg>
                    </div>
                  </div>
                  <div class="flex-1 p-3">
                    <h3 class="text-xl font-semibold">
                      Proper Planning of decommissioning,setting goals and
                      establising a budget.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
