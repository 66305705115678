import React from "react";
import Section1 from "../homepage/section1";
import Section2 from "../homepage/section2";
import Section3 from "../homepage/section3";
import Section4 from "../homepage/section4";
import Section5 from "../homepage/section5";
import Section6 from "../homepage/section6";
import Section7 from "../homepage/section7";

export default function index() {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </div>
  );
}
