import React from "react";
import Image from "../../images/house1.jpg";
import Image1 from "../../images/house2.jpg";
import Image2 from "../../images/house3.jpg";
import Image3 from "../../images/house4.jpg";
import Image4 from "../../images/house5.jpg";
import Image5 from "../../images/house6.jpg";

export default function Section8() {
  return (
    <div>
      <section class="py-6 bg-white" style={{ fontFamily: "Red Hat Display" }}>
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl text-center mx-auto mb-16">
            <h2 class="text-2xl md:text-4xl font-bold font-heading tracking-px-n leading-tight mb-5 text-blue-800 text-center">
              Warehouse Area with Dedicated Secured Storage Areas
            </h2>
          </div>
          <div class="flex flex-wrap -m-9">
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full  h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image4}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full  h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image2}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full  h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image3}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full  h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/3 p-5">
              <div class="group cursor-pointer">
                <div class="mb-6 overflow-hidden rounded-lg">
                  <img
                    class="w-full  h-72 rounded-lg transform hover:scale-105 transition duration-500"
                    src={Image5}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
