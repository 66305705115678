import React from "react";
import { Link } from "react-router-dom";
import ImageOne from "../../images/datacenterdec.webp";
import ImageTwo from "../../images/tower.webp";
import ImageThree from "../../images/desk.webp";

export default function Section3() {
  return (
    <div>
      <section
        class="sm:pt-14 sm:pb-14 sm:px-24 pt-16 pb-16 px-4 bg-white overflow-hidden"
        style={{ fontFamily: "Red Hat Display" }}
      >
        <div class="container px-4 mx-auto">
          <h2 class="text-2xl md:text-5xl font-bold font-heading tracking-px-n leading-tight text-blue-800 text-center">
            Streamlined Decommisioning Services
          </h2>
          <p class="text-md text-gray-900 text-opacity-80 font-medium leading-relaxed text-center mt-5">
            With our comprehensive decommissioning process, we handle everything
            from logistics and data destruction to proper disposal, adhering to
            strict regulatory standards.
          </p>
          <div class="mb-20 flex flex-wrap items-end -m-8"></div>
          <div class="flex flex-wrap -m-3">
            <div class="w-full md:w-1/3 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-3xl overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={ImageOne}
                    alt=""
                  />
                  <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div
                      class="px-8 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                      style={{ backdropFilter: "blur(5px)" }}
                    >
                      <h3 class="text-lg text-white text-center font-semibold">
                        End-to-End Decommisioning Services
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/3 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-3xl overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={ImageTwo}
                    alt=""
                  />
                  <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div
                      class="px-8 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                      style={{ backdropFilter: "blur(5px)" }}
                    >
                      <h3 class="text-lg text-white text-center font-semibold">
                        Seamless Telecom Tower site operations
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div class="w-full md:w-1/3 p-3">
              <Link to="/" class="flex justify-center md:block h-full">
                <div class="relative h-full rounded-3xl overflow-hidden">
                  <img
                    class="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src={ImageThree}
                    alt=""
                  />
                  <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div
                      class="px-8 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                      style={{ backdropFilter: "blur(5px)" }}
                    >
                      <h3 class="text-lg text-white text-center font-semibold">
                        Recycled IT assets parts for purchase
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
